import React, { useEffect, useState } from 'react';
import './Layout.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { NAVIGATION } from 'config';

import { pageInfoActions } from 'app/redux/actions';

const mapStateToProps = (state) => {
    const { pageInfo, auth } = state;
    return { pageInfo, auth }
}

const actionCreators = {
    changeLang: pageInfoActions.change_lang
}

const ScreenWithNavBar = props => {

    return (
        <div className={`page-layout ${props.className ? props.className : ''}`}>

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

        </div>
    )
}

const ScreenWithNavBarAndFooter = props => {

    return (
        <div className={`page-layout ${props.className ? props.className : ''}`}>

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

            {/* Footer */}
            <Footer />

        </div>
    )
}

const TopNavBar = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, changeLang } = props;
    const lang = pageInfo.display_lang;
    const { locale_string } = pageInfo;
    const { isLogin, user } = props.auth;

    const [navbarScroll, setNavbarScroll] = useState(false);

    useEffect(() => {
        document.addEventListener('scroll', handlePageScroll)

        return () => {
            document.removeEventListener('scroll', handlePageScroll)
        }
    }, [])

    function handlePageScroll(e) {
        // console.log('pageScroll', window.scrollY);
        if (window.scrollY > 70) {
            setNavbarScroll(true);
        } else {
            setNavbarScroll(false);
        }

    }

    return (
        <nav className={`navbar navbar-expand-lg fixed-top top-navbar ${navbarScroll ? 'scroll' : ''}`}>
            <div className="container-xl">
                <button className="navbar-toggler ms-3 order-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                    <i className="far fa-bars"></i>
                </button>
                <Link className="navbar-brand order-1" to={NAVIGATION.DASHBOARD}>
                    <img src="/public_assets/images/header-logo.svg" alt=""></img>
                </Link>
                <div className="collapse navbar-collapse order-4 order-lg-3" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto me-auto pt-3 pt-md-0 navbar-center">
                        <li className="nav-item">
                            <NavLink to={NAVIGATION.DASHBOARD} className="nav-link">{text.top_nav_bar.home[lang]}</NavLink>
                        </li>
                        <li className="nav-item">
                            <a href="#features" className="nav-link">{text.top_nav_bar.features[lang]}</a>
                        </li>
                        <li className="nav-item">
                            <NavLink to={NAVIGATION.USER_MANUAL} className="nav-link">{text.top_nav_bar.user_manual[lang]}</NavLink>
                        </li>
                        <li className="nav-item mobile my-3">
                            <a href={`tel:0912509812`} className="btn btn-primary w-100">{text.top_nav_bar.buy_now[lang]}: {(499000).toLocaleString(locale_string)}đ</a>
                        </li>
                    </ul>
                </div>
                <ul className="navbar-nav ms-3 order-2 order-lg-4 desktop">
                    <li className="nav-item">
                        <a href={`tel:0912509812`} className="btn btn-primary">{text.top_nav_bar.buy_now[lang]}: {(499000).toLocaleString(locale_string)}đ</a>
                    </li>
                </ul>
            </div>
        </nav>
    )
})

const Footer = connect(mapStateToProps, actionCreators)((props) => {
	const { pageInfo } = props;
	const lang = pageInfo.display_lang;

	return (
        <>
		<footer className="site-footer">
			<div className="container-xl">
            <div className="row justify-content-between my-5">
					<div className="col-md-6">
						{/* <img
							src="/public_assets/images/logo-white.svg"
							height={40}
							alt=""
							className="footer-brand"
						/> */}
						<h5>CÔNG TY CỔ PHẦN LOCACAFE VIỆT NAM</h5>
						<ul className="footer-links">
                            <li>
                                <p><b>Địa chỉ:</b> Số 2 Văn Cao, Phường Thụy Khuê, Quận Tây Hồ, TP Hà Nội, Việt Nam</p>
                            </li>
                            <li>
                                <p><b>Mã số thuế:</b> 0110461797</p>
                            </li>
                            <li>
                                <p><b>Đại diện theo pháp luật:</b> Ông VŨ TUẤN DŨNG - Chức vụ: Giám Đốc</p>
                            </li>
						</ul>
					</div>
					<div className="col-md-2 mt-5 mt-md-0">
						<h5>{text.footer.resources[lang]}</h5>
						<ul className="footer-links">
                            <li>
                                <Link to={NAVIGATION.DASHBOARD} className="nav-link">{text.top_nav_bar.home[lang]}</Link>
                            </li>
                            <li>
                                <a href="#features" className="nav-link">{text.top_nav_bar.features[lang]}</a>
                            </li>
                            <li>
                                <Link to={NAVIGATION.USER_MANUAL} className="nav-link">{text.top_nav_bar.user_manual[lang]}</Link>
                            </li>
						</ul>
					</div>
				</div>
				<div id="copyright" className="site-copyright">
					<div className="row">
						<div className="col-md-6 text-center text-md-start fw-semibold">
							&copy; 2024 TingMe.
							All rights reserved.
						</div>
					</div>
				</div>
			</div>
		</footer>
        </>
	);
});

export {
    ScreenWithNavBar,
    ScreenWithNavBarAndFooter
}