import React, { useState, useEffect, useRef } from 'react';
import './Dashboard.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { alertActions } from 'app/redux/actions';

import { NAVIGATION } from 'config';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { ScreenWithNavBarAndFooter, Slider, CountdownTimer } from 'app/components';
import { Autoplay } from "swiper/modules";

import { decodeHtmlContent } from "utils/AppHelper";

import { motion } from "framer-motion";

import { homepageService } from "services";

const mapStateToProps = (state) => {
    const { pageInfo, auth, order } = state;
    return { pageInfo, auth, order }
}

const actionCreators = {
    alertSuccess: alertActions.success,
    alertError: alertActions.error
}


const Dashboard = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError } = props;
    const lang = pageInfo.display_lang;
	const { locale_string } = pageInfo; 
 
    return (
        <ScreenWithNavBarAndFooter className="page-home">
            <section className="page-section home-header">
                <div
                    className="section-bg d-flex justify-content-center align-items-center w-100" 
                    style={{ backgroundImage: 'url(/public_assets/images/home/home-header/home-header-wave.png)' }}
                ></div>
                <div className="container-xl">
                    <div className="row align-items-center">
                        <div className="col-md-6 order-2 order-md-1">
                            <div className="sec-header text-center text-md-start">
                                <motion.h1
                                    initial={{ opacity: 0, scale: 2 }}
                                    whileInView={{ opacity: 1, scale: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-line-title"
                                ><span className="line"></span>TingMe</motion.h1>
                                <motion.h2
                                    initial={{ opacity: 0, scale: 2 }}
                                    whileInView={{ opacity: 1, scale: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title mb-0"
                                >Loa thông báo Giao Dịch</motion.h2>
                                <motion.h2
                                    initial={{ opacity: 0, scale: 2 }}
                                    whileInView={{ opacity: 1, scale: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >Chuyển Tiền tức thời!</motion.h2>
                                <motion.p
                                    initial={{ opacity: 0 }}
                                    whileInView={{ opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-description ms-0"
                                >
                                    Đơn giản hóa việc quản lý thanh toán với TingMe! Chỉ với <b>{(499000).toLocaleString(locale_string)}đ</b>, loa thông minh giúp thông báo mọi giao dịch chuyển tiền qua mã QR nhanh chóng, chính xác và bảo mật. Dù bạn là chủ cửa hàng, quán cà phê hay siêu thị, TingMe là giải pháp hoàn hảo để bạn không bỏ lỡ bất kỳ khoản thanh toán nào!
                                </motion.p>
                                <motion.div
                                    initial={{ opacity: 0, scale: 2 }}
                                    whileInView={{ opacity: 1, scale: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="mt-4"
                                >
                                    <a href={`tel:0912509812`} className="btn btn-primary me-3"><i className="fa-solid fa-cart-shopping"></i>Mua Ngay</a>
                                    <Link to={NAVIGATION.USER_MANUAL} className="btn btn-outline-primary"><i className="fa-regular fa-book"></i>Hướng dẫn sử dụng</Link>
                                </motion.div>
                            </div>
                        </div>
                        <div className="col-md-6 order-1 order-md-2 position-relative">
                            <motion.img
                                initial={{ opacity: 0, scale: 3 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                src="/public_assets/images/home/home-header/device-mockup.png" alt="" className="img-fluid w-100 mb-5 mb-md-0"
                            ></motion.img>
                            <motion.a
                                initial={{ x: 50, opacity: 0 }}
                                whileInView={{ x: 0, opacity: 1 }}
                                transition={{ ease: "easeOut", duration: .5 }}
                                viewport={{ once: true }}
                                className="btn btn-primary btn-rounded header-image-price-tag"
                                href={`tel:0912509812`}
                            >
                                <i className="fa-solid fa-tag me-2"></i>{(499000).toLocaleString(locale_string)}đ
                            </motion.a>
                        </div>
                    </div>
                </div>
            </section>
            <section id="highlight" className="page-section highlight-section">
                <div className="container-xl">
                    <div className="sec-content">
                        <div className="row justify-content-center">
                            <div className="col-6 col-md-4">
                                <div className="highlight-item">
                                    <p>{(100000).toLocaleString(locale_string)}+</p>
                                    <h6>Thiết bị xuất xưởng</h6>
                                </div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className="highlight-item">
                                    <p>1 NĂM</p>
                                    <h6>Bảo hành 1 đổi 1</h6>
                                </div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className="highlight-item">
                                    <p>{(99.9).toLocaleString(locale_string)}%</p>
                                    <h6>Khách hàng hài lòng</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="features" className="page-section features-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <motion.h2
                            initial={{ x: -200, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-title"
                        >TingMe - Tiện lợi &amp; An toàn</motion.h2>
                    </div>
                    <div className="sec-content">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-5">
                                <motion.img
                                    initial={{ opacity: 0, scale: 0 }}
                                    whileInView={{ opacity: 1, scale: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    src="/public_assets/images/home/home-header/device-mockup-2.png" alt="" className="img-fluid w-100 mb-5 mb-md-0"
                                ></motion.img>
                            </div>
                            <div className="col-md-5">
                                <motion.div
                                    initial={{ y: 200, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="feature-item"
                                >
                                    <div className="item-icon">
                                        <i className="fa-regular fa-3x fa-bell-on"></i>
                                    </div>
                                    <div className="item-content">
                                        <h3>Thông Báo Tức Thời</h3>
                                        <p>Nhận thông báo giao dịch ngay lập tức qua loa, giúp bạn cập nhật chính xác các khoản tiền chuyển.</p>
                                    </div>
                                </motion.div>
                                <motion.div
                                    initial={{ y: 200, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="feature-item"
                                >
                                    <div className="item-icon">
                                        <i className="fa-regular fa-3x fa-cash-register"></i>
                                    </div>
                                    <div className="item-content">
                                        <h3>Hỗ Trợ Đa Ngân Hàng</h3>
                                        <p>Tích hợp linh hoạt với nhiều ngân hàng và ví điện tử phổ biến, đảm bảo mọi giao dịch được đồng bộ mượt mà.</p>
                                    </div>
                                </motion.div>
                                <motion.div
                                    initial={{ y: 200, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="feature-item"
                                >
                                    <div className="item-icon">
                                        <i className="fa-regular fa-3x fa-clock-one"></i>
                                    </div>
                                    <div className="item-content">
                                        <h3>Dễ Dàng Cài Đặt</h3>
                                        <p>Chỉ với vài bước kết nối đơn giản, TingMe sẵn sàng hoạt động mà không cần kỹ năng kỹ thuật.</p>
                                    </div>
                                </motion.div>
                                <motion.div
                                    initial={{ y: 200, opacity: 0 }}
                                    whileInView={{ y: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="feature-item"
                                >
                                    <div className="item-icon">
                                        <i className="fa-regular fa-3x fa-shield-keyhole"></i>
                                    </div>
                                    <div className="item-content">
                                        <h3>Bảo Mật Cao</h3>
                                        <p>Tất cả dữ liệu giao dịch được bảo vệ bằng các tiêu chuẩn mã hóa hàng đầu, đảm bảo an toàn tuyệt đối.</p>
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="cta" className="page-section cta-section">
                <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/cta-section/cta-section-bg.jpg)' }}></div>
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="sec-header">
                                <motion.h2
                                    initial={{ x: -200, opacity: 0 }}
                                    whileInView={{ x: 0, opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="sec-title"
                                >Tham gia cùng hơn <span className="text-light">{(30000).toLocaleString(locale_string)}</span> Đối Tác <br className="desktop" />sử dụng TingMe ngay hôm nay!</motion.h2>
                                <motion.div
                                    initial={{ opacity: 0, scale: 2 }}
                                    whileInView={{ opacity: 1, scale: 1 }}
                                    transition={{ ease: "easeOut", duration: .5 }}
                                    viewport={{ once: true }}
                                    className="mt-5"
                                >
                                    <a href={`tel:0912509812`} className="btn btn-light me-3"><i className="fa-solid fa-cart-shopping"></i>Mua Ngay</a>
                                    <Link to={NAVIGATION.USER_MANUAL} className="btn btn-outline-light"><i className="fa-regular fa-book"></i>Hướng dẫn sử dụng</Link>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
})

const PartnerSection = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError } = props;
    const lang = pageInfo.display_lang;

    const partners_row_1 = [
        {
            "name": "Seagull Restaurant",
            "img_url": "/public_assets/images/home/partner-section/logo/seagull-coffee.png",
        },
        {
            "name": "Nhà Hàng Cái Mâm",
            "img_url": "/public_assets/images/home/partner-section/logo/bep-cai-mam.png",
        },
        {
            "name": "Nhà Hàng Cơm Xưa",
            "img_url": "/public_assets/images/home/partner-section/logo/com-xua.png",
        },
        {
            "name": "Liberty Central",
            "img_url": "/public_assets/images/home/partner-section/logo/liberty-central.png",
        },
        {
            "name": "Chevalier",
            "img_url": "/public_assets/images/home/partner-section/logo/chevalier.png",
        },
        {
            "name": "Chef Dzung Buffet",
            "img_url": "/public_assets/images/home/partner-section/logo/chef-dzung.png",
        },
        {
            "name": "NH Vị An",
            "img_url": "/public_assets/images/home/partner-section/logo/vi-an.png",
        },
        {
            "name": "Khuê Restaurant",
            "img_url": "/public_assets/images/home/partner-section/logo/le-jardin.png",
        },
        {
            "name": "IL Divo",
            "img_url": "/public_assets/images/home/partner-section/logo/il-divo.png",
        },
        {
            "name": "Hà Nội Corner",
            "img_url": "/public_assets/images/home/partner-section/logo/ha-noi-corner.png",
        },
        {
            "name": "Ngon Garden",
            "img_url": "/public_assets/images/home/partner-section/logo/ngon-garden.png",
        },
        {
            "name": "Runam Bistro",
            "img_url": "/public_assets/images/home/partner-section/logo/runam.png",
        },
        {
            "name": "Mixue",
            "img_url": "/public_assets/images/home/partner-section/logo/mixue.png",
        },
        {
            "name": "BaMi SaMi",
            "img_url": "/public_assets/images/home/partner-section/logo/bami-sami.png",
        },
        {
            "name": "T-Dessert",
            "img_url": "/public_assets/images/home/partner-section/logo/t-dessert.png",
        },
        {
            "name": "Cà phê Nấu",
            "img_url": "/public_assets/images/home/partner-section/logo/nau-ca-phe.png",
        },
        {
            "name": "An Nam Quán",
            "img_url": "/public_assets/images/home/partner-section/logo/an-nam-quan.png",
        },
        {
            "name": "Vista Restaurant",
            "img_url": "/public_assets/images/home/partner-section/logo/the-cliff.png",
        },
        {
            "name": "Temple Restaurant",
            "img_url": "/public_assets/images/home/partner-section/logo/the-temple.png",
        },
        {
            "name": "Hải sản Hằng Loan",
            "img_url": "/public_assets/images/home/partner-section/logo/hai-san-hang-loan.png",
        },
        {
            "name": "Cafe Cardinal",
            "img_url": "/public_assets/images/home/partner-section/logo/cafe-cardinal.png",
        },
    ]

    const partners_row_2 = [
        {
            "name": "Sky 36",
            "img_url": "/public_assets/images/home/partner-section/logo/sky36.png",
        },
        {
            "name": "Xofa Cafe & Bistro",
            "img_url": "/public_assets/images/home/partner-section/logo/xofa.jpg",
        },
        {
            "name": "Lofita Tea & Coffee",
            "img_url": "/public_assets/images/home/partner-section/logo/lofita.png",
        },
        {
            "name": "Sorae Restaurant Lounge",
            "img_url": "/public_assets/images/home/partner-section/logo/sorae.png",
        },
        {
            "name": "Grand Palace",
            "img_url": "/public_assets/images/home/partner-section/logo/grand-palace.png",
        },
        {
            "name": "Nhà Hàng Quá Ngon",
            "img_url": "/public_assets/images/home/partner-section/logo/qua-ngon.png",
        },
        {
            "name": "Phố 79",
            "img_url": "/public_assets/images/home/partner-section/logo/pho-79.png",
        },
        {
            "name": "Hotel Nikko Saigon",
            "img_url": "/public_assets/images/home/partner-section/logo/nikko.png",
        },
        {
            "name": "Nhà hàng Pao Quán",
            "img_url": "/public_assets/images/home/partner-section/logo/pao.png",
        },
        {
            "name": "Nhà Hàng 04",
            "img_url": "/public_assets/images/home/partner-section/logo/hai-san-04.png",
        },
        {
            "name": "Phở Mong",
            "img_url": "/public_assets/images/home/partner-section/logo/pho-mong.png",
        },
        {
            "name": "Bếp Việt, Hàn Vi Quán",
            "img_url": "/public_assets/images/home/partner-section/logo/bep-viet-han-vi-quan.png",
        },
        {
            "name": "Home 6",
            "img_url": "/public_assets/images/home/partner-section/logo/home6.png",
        },
        {
            "name": "Aha Cafe",
            "img_url": "/public_assets/images/home/partner-section/logo/aha.png",
        },
        {
            "name": "C.O.C Coffee",
            "img_url": "/public_assets/images/home/partner-section/logo/coc.png",
        },
        {
            "name": "Cua Ngon Cà Mau",
            "img_url": "/public_assets/images/home/partner-section/logo/cua.png",
        },
        {
            "name": "Nhà Hàng Cá Hồi Song Nhi Sa Pa",
            "img_url": "/public_assets/images/home/partner-section/logo/song-nhi-sa-pa.png",
        },
        {
            "name": "Ohana Coffee",
            "img_url": "/public_assets/images/home/partner-section/logo/ohana.png",
        },
        {
            "name": "Sline Coffee",
            "img_url": "/public_assets/images/home/partner-section/logo/sline-coffee.png",
        },
        {
            "name": "Nhà Hàng Vườn Quê",
            "img_url": "/public_assets/images/home/partner-section/logo/vuon-que.png",
        },
        {
            "name": "Hệ thống Nhà hàng chay Mộc Nhiên - Chi nhánh Thủ Đức",
            "img_url": "/public_assets/images/home/partner-section/logo/moc-nhien-chay.png",
        },
        {
            "name": "Hà Tôm Hùm",
            "img_url": "/public_assets/images/home/partner-section/logo/ha-hai-san.png",
        },
        {
            "name": "Đậu Má Mix - Đậu Nành và Rau Má - Trần Quốc Thảo",
            "img_url": "/public_assets/images/home/partner-section/logo/dau-ma-mix.png",
        },
        {
            "name": "PAMI 01",
            "img_url": "/public_assets/images/home/partner-section/logo/pami.png",
        },
    ]

    return (
        <section id="partner" className="page-section partner-section">
            <div className="section-bg" style={{ backgroundImage: 'url(/public_assets/images/home/partner-section/partner-section-bg.jpg)' }}></div>
            <div className="container-xl">
                <div className="sec-header text-center">
                    <motion.h2
                        initial={{ x: -200, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        viewport={{ once: true }}
                        className="sec-title"
                    >Our Strategic Partners</motion.h2>
                    <motion.p
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        viewport={{ once: true }}
                        className="sec-description"
                    >
                        Partnering with trusted allies to deliver sustainable value
                    </motion.p>
                </div>
            </div>
            <div className="sec-content">
                <Slider
                    className="partner-img-slider mb-3"
                    list={partners_row_1}
                    renderItem={(item, index) => (
                        <img src={item.img_url} alt={item.name} key={'partnerImgSlider_' + index} className="img-fluid w-100"></img>
                    )}
                    overrideConfig={{
                        modules: [Autoplay],
                        allowTouchMove: false,
                        slidesPerView: 8.5,
                        slidesPerGroup: 9,
                        autoplay: {
                            delay: 5000
                        },
                        centerInsufficientSlides: true
                    }}
                />

                <Slider
                    className="partner-img-slider"
                    list={partners_row_2}
                    renderItem={(item, index) => (
                        <img src={item.img_url} alt={item.name} key={'partnerImgSlider_' + index} className="img-fluid w-100"></img>
                    )}
                    overrideConfig={{
                        modules: [Autoplay],
                        allowTouchMove: false,
                        slidesPerView: 8.5,
                        slidesPerGroup: 9,
                        autoplay: {
                            delay: 5000
                        },
                        centerInsufficientSlides: true
                    }}
                />
            </div>
            <div className="container-xl">
                <div className="marketpartner-wrapper">
                    <div className="sec-header text-center">
                        <motion.h2
                            initial={{ x: -200, opacity: 0 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-title"
                        >Our <span className="text-primary">Market Development</span> Partners</motion.h2>
                        <motion.p
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            transition={{ ease: "easeOut", duration: .5 }}
                            viewport={{ once: true }}
                            className="sec-description"
                        >
                            Collaborating with reputable partners to provide the best value for customers
                        </motion.p>
                    </div>
                    <div className="sec-content text-center">
                        <Slider
                            className="partner-img-slider"
                            list={[...partners_row_2, ...partners_row_2].reverse()}
                            renderItem={(item, index) => (
                                <img src={item.img_url} alt={item.name} key={'partnerImgSlider_' + index} className="img-fluid w-100"></img>
                            )}
                            overrideConfig={{
                                modules: [Autoplay],
                                allowTouchMove: false,
                                slidesPerView: 9,
                                slidesPerGroup: 9,
                                autoplay: {
                                    delay: 5000
                                },
                                centerInsufficientSlides: true
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
})

const NewsSection = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo, auth, alertSuccess, alertError } = props;
    const lang = pageInfo.display_lang;

    const news = [
        {
            "title": "[Yahoo] LocaMos Global AG Officially Inaugurates New Headquarters in Zug, Switzerland, Bridging MarTech and Blockchain Innovation",
            "description": "LocaMos Global AG, an emerging name in the technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://finance.yahoo.com/news/locamos-global-ag-officially-inaugurates-034100325.html",
            "img_url": "https://s.yimg.com/ny/api/res/1.2/wCXsAxlezV.JLsLmkuKnmQ--/YXBwaWQ9aGlnaGxhbmRlcjt3PTk2MDtoPTY4MQ--/https://media.zenfs.com/en/newsfile_64/adb3aa3e19ddfa3700b678348d18581f",
        },
        {
            "title": "[Business Insider] LocaMos Global AG: Pioneering a Revolutionary Blend of MarTech and Blockchain",
            "description": "LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://markets.businessinsider.com/news/stocks/locamos-global-ag-pioneering-a-revolutionary-blend-of-martech-and-blockchain-1032786035",
            "img_url": "/public_assets/images/news/news-1.jpg",
        },
        {
            "title": "[Benzinga] LocaMos Global AG commits to applying technology to address real-life challenges. The company offers a range of tools that help attract and engage customers through high-experiential features to support physical businesses, creating smoother business operations and growth.",
            "description": "LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://www.benzinga.com/pressreleases/23/11/35636290/locamos-global-ag-pioneering-a-revolutionary-blend-of-martech-and-blockchain",
            "img_url": "/public_assets/images/news/news-2.jpg",
        },
        {
            "title": "[News Max] LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland",
            "description": "LocaMos Global AG, a technology company based in Zug, Switzerland, recently established, is making waves by combining two crucial facets in the technology industry: MarTech (Marketing Technology) and Blockchain. MarTech emphasizes utilizing technology to optimize and enhance marketing campaigns, while Blockchain has the potential to transform many sectors through its security and transparency in transactions.",
            "url": "https://pr.newsmax.com/article/LocaMos-Global-AG-Pioneering-a-Revolutionary-Blend-of-MarTech-and-Blockchain?storyId=6549e967aabbe7000889a161&fbclid=IwAR2GzfuyxswWFZHM5aLdlhJKoWBRjTR4aCwjGiU1DqcqJf5NNIxUdbXUSOk_aem_AehULbt_gCLI7P0HxUPS64lhOmWMIdh46HdHz3cLs3a-NmaEkM0gYbla8wừycQujJh8",
            "img_url": "https://storage.googleapis.com/mmstudio-images/gallery/AhvOgFGA4jcj9Xtx9NZIpIwF4Vw2/89112505-1699329968-1.jpg",
        },
        {
            "title": "[TGAM] LocaMos Global AG: Pioneering a Revolutionary Blend of MarTech and Blockchain",
            "description": "LocaMos Global AG, an emerging name in the Marketing technology and blockchain sector, proudly announces the official inauguration of its new headquarters in Zug, Switzerland. This significant milestone marks a crucial juncture in the company's path of growth and development.",
            "url": "https://www.theglobeandmail.com/investing/markets/markets-news/MarketersMEDIA%20Newswire/21772309/locamos-global-ag-pioneering-a-revolutionary-blend-of-martech-and-blockchain/?fbclid=IwAR2gZL6arEordyYiLCe4KFjHIgOq2Tl6uJJjBauzNabt9qAy2barC64eymg_aem_Aehjv52c61JvXo8gw0HebSzgI5HKXPePgq0BPU-ueX52dFqE_r7yiH5vjJbDtxAdx7g",
            "img_url": "/public_assets/images/news/news-1.jpg",
        },
    ]

    return (
        <section id="news" className={`page-section news-section ${props.className !== '' ? props.className : ''}`}>
            <div className="container-xl">
                <div className="sec-header text-center">
                    <motion.h2
                        initial={{ x: -200, opacity: 0 }}
                        whileInView={{ x: 0, opacity: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        viewport={{ once: true }}
                        className="sec-title"
                    ><span className="text-primary">The Press</span> Talks About Us</motion.h2>
                    <motion.p
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ ease: "easeOut", duration: .5 }}
                        viewport={{ once: true }}
                        className="sec-description"
                    >
                        LocaGo is praised by magazines for its creativity and effectiveness
                    </motion.p>
                </div>
                <div className="sec-content">
                    <Slider
                        className="news-slider"
                        list={news}
                        renderItem={(item, index) => (
                            <a href={item.url} target="_blank" className="article">
                                <div className="article-image">
                                    <img src={item.img_url} alt="" className="img-fluid w-100"></img>
                                </div>
                                <div className="article-info">
                                    <h4 className="article-title" dangerouslySetInnerHTML={{ __html: item.title }}></h4>
                                    <p className="article-description" dangerouslySetInnerHTML={{ __html: decodeHtmlContent(item.description) }}></p>
                                </div>
                            </a>
                        )}
                        overrideConfig={{
                            slidesPerView: 1,
                            breakpoints: {
                                768: {
                                    slidesPerView: 3
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </section>
    )
})

export {
    Dashboard,
    NewsSection
}